import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const DomainPage = () => (
  <div>
    <SEO 
        title="Buy Domain Name - Website Domain Registration &amp; Availability" 
        description="At Sheephostingbay, we don’t just sell domain names. We provide you with 
        the best domain management services. Additionally we give you an 
        affordable domain together with each of our cloud hosting 
        packages."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">Domain Names from <span className="block text-indigo-600 xl:inline">Sheephostingbay</span></p>
              <p className="section-after-title">
                At Sheephostingbay, we don’t just sell domain names. We provide you with 
                the best domain management services. Additionally we give you an 
                affordable domain together with each of our cloud hosting 
                packages.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="rounded-md shadow">
                      <a href="/domain-search" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                      Get your Domain
                      </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a href="https://sheephostingbay.duoservers.com/domains-brochure/" rel="noreferrer" target="_blank" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      Download Brochure
                      </a>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-32  connect-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                      <div className="p-6">
                          <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  <a href="https://laravel.com/docs">One–Stop Domain Control Solution</a>
                              </div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Our Domain Manager includes an enormous set of 
                              controls for administering all your domain names Through its intuitive interface, you can lock & unlock 
                              your domain name, alter its DNS records and your WHOIS details, set up unlimited sub–domains or start 
                              a domain name transfer operation instantaneously.
                              </div>
                          </div>
                      </div>
                      <div
                          className="p-6 border-t border-gray-200 dark:border-gray-700 md:border-t-0 md:border-l">
                          <div className="flex items-center">
                              <svg fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                  <path
                                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z">
                                  </path>
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  <a href="https://laracasts.com">Whois Privacy Protection</a>
                              </div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> This specific service is available with the majority 
                              of the universal and country–specific domains that we’re offering. Go look at the TLD information page on our 
                              website to learn which top–level domain names are Whois Privacy Protection–eligible. </div>
                          </div>
                      </div>
                      <div className="p-6 border-t border-gray-200 dark:border-gray-700">
                          <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  <a href="https://tailwindcss.com/">Multiple Domain Administration</a>
                              </div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Working with our Domain Manager is quite easy, no matter 
                              if you’ve got one or many domain names to administer. Through its easy–to–work–with interface, you can select many 
                              domains simultaneously and renew them (for one or multiple years), modify their nameservers or modify the WHOIS 
                              info pertaining to them in no time. </div>
                          </div>
                      </div>
                      <div className="p-6 border-t border-gray-200 dark:border-gray-700 md:border-l">
                          <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  Bulk Domain Name Registration Options</div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                              Registering several different domains all at once can often be a tough affair. That’s why our company offers 
                              a bulk domain name registration tool incorporated into our web hosting Control Panel. It permits you to 
                              quickly register lots of domains at once. </div>
                          </div>
                      </div>

                      <div className="p-6 border-t border-gray-200 dark:border-gray-700">
                          <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  <a href="https://tailwindcss.com/">GeoIP Redirection Tool</a>
                              </div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Working with our Domain Manager is quite easy, no matter 
                              if you’ve got one or many domain names to administer. Through its easy–to–work–with interface, you can select many 
                              domains simultaneously and renew them (for one or multiple years), modify their nameservers or modify the WHOIS 
                              info pertaining to them in no time. </div>
                          </div>
                      </div>
                      <div className="p-6 border-t border-gray-200 dark:border-gray-700 md:border-l">
                          <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  viewBox="0 0 24 24" className="w-8 h-8 text-gray-400 dark:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                              </svg>
                              <div
                                  className="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                  Cut–price Domain Names</div>
                          </div>
                          <div className="ml-12">
                              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                              Here, you can get a cut–price domain name for your site, selecting from over 50 generic and country–code domain 
                              extensions. Utilizing the simple to use Domain Names Manager, you are able to control all of the adjustments 
                              of your domains from just one location. You’ll be able to alter your WHOIS information, to create custom 
                              DNS records, to park domains and many more.</div>
                          </div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                              </svg>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Compare Our Prices</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      Uncertain which domain name to select? Go and visit our domain name price comparison table.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">TLD Details</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      Find just what is the minimum registration period or if you can get Whois Privacy Protection for the domain name.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                            </svg>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Service Guarantees</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      Sheephostingbay offers a 99.9% network uptime guarantee with every domain name registration.
                      </dd>
                  </div>
                  <div className="relative">
                      <dt>
                          <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                          </h4>
                          <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Contact Us</h5>
                      </dt>
                      <dd className="mt-1 ml-24 text-base text-gray-500">
                      Do you have any questions? Do not delay – contact us at any moment.
                      </dd>
                  </div>
              </dl>
          </div>
      </div>
    </div>
    <Subscribe/>
    <Footer/>
  </div>
)

export default DomainPage
